import React from 'react';

const imgStyle = {
  width: '12%',
  aspectRatio: '3/2',
  objectFit: 'contain',
};

const baseUri =
  'https://raw.githubusercontent.com/devicons/devicon/master/icons/';

const SkillList = () => (
  <div>
    <h3>Skills</h3>
    <p>
      <a
        href="https://www.w3.org/html/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={`${baseUri}html5/html5-original-wordmark.svg`}
          alt="html5"
          style={imgStyle}
        />
      </a>
      <a
        href="https://www.w3schools.com/css/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={`${baseUri}css3/css3-original-wordmark.svg`}
          alt="css3"
          style={imgStyle}
        />
      </a>
      <a
        href="https://www.w3schools.com/js/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={`${baseUri}javascript/javascript-original.svg`}
          alt="javascript"
          style={imgStyle}
        />
      </a>
      <a
        href="https://www.typescriptlang.org/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={`${baseUri}typescript/typescript-original.svg`}
          alt="typescript"
          style={imgStyle}
        />
      </a>
      <a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">
        <img
          src={`${baseUri}react/react-original-wordmark.svg`}
          alt="react"
          style={imgStyle}
        />
      </a>
      <a href="https://nextjs.org/" target="_blank" rel="noopener noreferrer">
        <img
          src={`${baseUri}nextjs/nextjs-line.svg`}
          alt="nextjs"
          style={imgStyle}
        />
      </a>
      <a href="https://jestjs.io" target="_blank" rel="noopener noreferrer">
        <img
          src={`${baseUri}jest/jest-plain.svg`}
          alt="jest"
          style={imgStyle}
        />
      </a>
      <a href="https://cypress.io" target="_blank" rel="noopener noreferrer">
        <img
          src="https://www.cypress.io/images/layouts/navbar-brand.svg"
          alt="cypress"
          style={imgStyle}
        />
      </a>
      <a
        href="https://reactnative.dev/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={`${baseUri}react/react-original-wordmark.svg`}
          alt="reactnative"
          style={imgStyle}
        />
      </a>
      <a href="https://redux.js.org" target="_blank" rel="noopener noreferrer">
        <img
          src={`${baseUri}redux/redux-original.svg`}
          alt="redux"
          style={imgStyle}
        />
      </a>
      <a href="https://sass-lang.com" target="_blank" rel="noopener noreferrer">
        <img
          src={`${baseUri}sass/sass-original.svg`}
          alt="sass"
          style={imgStyle}
        />
      </a>
      <a
        href="https://www.cprogramming.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={`${baseUri}c/c-original.svg`} alt="c" style={imgStyle} />
      </a>

      <a
        href="https://www.w3schools.com/cpp/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={`${baseUri}cplusplus/cplusplus-original.svg`}
          alt="cplusplus"
          style={imgStyle}
        />
      </a>
      <a href="https://www.java.com" target="_blank" rel="noopener noreferrer">
        <img
          src={`${baseUri}java/java-original.svg`}
          alt="java"
          style={imgStyle}
        />
      </a>
      <a
        href="https://spring.io/projects/spring-boot"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={`${baseUri}spring/spring-original-wordmark.svg`}
          alt="spring"
          style={imgStyle}
        />
      </a>
      <a href="https://nodejs.org" target="_blank" rel="noopener noreferrer">
        <img
          src={`${baseUri}nodejs/nodejs-original-wordmark.svg`}
          alt="nodejs"
          style={imgStyle}
        />
      </a>
      <a href="https://expressjs.com" target="_blank" rel="noopener noreferrer">
        <img
          src={`${baseUri}express/express-original-wordmark.svg`}
          alt="express"
          style={imgStyle}
        />
      </a>
      <a
        href="https://chrome.google.com/webstore"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={`${baseUri}chrome/chrome-original.svg`}
          alt="Chrome Extensions"
          style={imgStyle}
        />
      </a>
      <a href="https://graphql.org" target="_blank" rel="noopener noreferrer">
        <img
          src={`${baseUri}graphql/graphql-plain-wordmark.svg`}
          alt="graphql"
          style={imgStyle}
        />
      </a>
      <a
        href="https://firebase.google.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={`${baseUri}firebase/firebase-plain-wordmark.svg`}
          alt="firebase"
          style={imgStyle}
        />
      </a>
      <a
        href="https://www.postgresql.org"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={`${baseUri}postgresql/postgresql-original-wordmark.svg`}
          alt="postgresql"
          style={imgStyle}
        />
      </a>
      <a
        href="https://www.mongodb.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={`${baseUri}mongodb/mongodb-original-wordmark.svg`}
          alt="mongodb"
          style={imgStyle}
        />
      </a>
      <a href="https://www.mysql.com" target="_blank" rel="noopener noreferrer">
        <img
          src={`${baseUri}mysql/mysql-original-wordmark.svg`}
          alt="mysql"
          style={imgStyle}
        />
      </a>
      <a
        href="https://www.docker.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={`${baseUri}docker/docker-original-wordmark.svg`}
          alt="docker"
          style={imgStyle}
        />
      </a>
      <a href="https://www.linux.org" target="_blank" rel="noopener noreferrer">
        <img
          src={`${baseUri}linux/linux-original.svg`}
          alt="linux"
          style={imgStyle}
        />
      </a>
      <a href="https://www.nginx.com" target="_blank" rel="noopener noreferrer">
        <img
          src={`${baseUri}nginx/nginx-original.svg`}
          alt="nginx"
          style={imgStyle}
        />
      </a>
      <a href="https://www.npmjs.com" target="_blank" rel="noopener noreferrer">
        <img
          src={`${baseUri}npm/npm-original-wordmark.svg`}
          alt="npm"
          style={imgStyle}
        />
      </a>
      <a href="https://yarnpkg.com" target="_blank" rel="noopener noreferrer">
        <img
          src={`${baseUri}yarn/yarn-original-wordmark.svg`}
          alt="yarn"
          style={imgStyle}
        />
      </a>
      <a href="https://git-scm.com" target="_blank" rel="noopener noreferrer">
        <img
          src={`${baseUri}git/git-original-wordmark.svg`}
          alt="git"
          style={imgStyle}
        />
      </a>
      <a href="https://github.com" target="_blank" rel="noopener noreferrer">
        <img
          src={`${baseUri}github/github-original-wordmark.svg`}
          alt="github"
          style={imgStyle}
        />
      </a>
      <a
        href="https://www.digitalocean.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={`${baseUri}digitalocean/digitalocean-original-wordmark.svg`}
          alt="digitalocean"
          style={imgStyle}
        />
      </a>
      <a
        href="https://azure.microsoft.com/en-us/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={`${baseUri}azure/azure-original-wordmark.svg`}
          alt="azure"
          style={imgStyle}
        />
      </a>
      <a
        href="https://aws.amazon.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={`${baseUri}amazonwebservices/amazonwebservices-original-wordmark.svg`}
          alt="amazonwebservices"
          style={imgStyle}
        />
      </a>
    </p>
  </div>
);

export default SkillList;
